@import 'variables';

.fancyIconButton {
  outline: none;
  background: transparent;
  border: 0;
  margin: 0.25rem;
}

.fancyIconButtonBackGround {
  display: none;
  &:hover {
    display: inherit;
  }
}

@mixin color-variant($color) {
  color: $color;
  &:hover {
    color: darken($color, 7.5%)
  }
}

@each $color, $value in $theme-colors {
  .fancyIconButton_#{$color} {
    @include color-variant($value);
  }
}

@each $color, $value in $colors {
  .fancyIconButton_#{$color} {
    @include color-variant($value);
  }
}

@each $color, $value in $grays {
  .fancyIconButton_#{$color} {
    @include color-variant($value);
  }
}

