@import 'variables';

@value primary: #{$primary};
@value secondary: #{$secondary};
@value info: #{$info};
@value success: #{$success};
@value warning: $warning;
@value danger: #{$danger};


:global {
  .rc-slider {
    height: $progress-height;
    padding: 0;
  }

  .rc-slider-rail {
    height: $progress-height;
    background-color: var(--bs-progress-bg);
    border-radius: $progress-border-radius;
  }

  .rc-slider-track {
    height: $progress-height;
    background-color: var(--bs-progress-bar-color);
    border-radius: $progress-border-radius;
  }

  .rc-slider-handle {
    border: none;
    border-radius: 0.3rem;
    height: 2rem;
    width: 2rem;
    margin-top: -0.5rem;
    background-color: $primary;
    opacity: 1;
    .night & {
      background-color: $primary--night;
    }

    z-index: 0;
    &:focus {
      box-shadow: var(--bs-btn-focus-box-shadow);
      border-color: transparent;
    }

    &:active {
      box-shadow: var(--bs-btn-active-shadow);

    }

    &:hover {
      z-index: 1; // hack for dice slider
      background-color: var(--bs-primary);
    }
  }
  .rc-handle-click-focus {
    cursor: grabbing !important;
  }
}
