.definitionEntry {
  display: flex;
  &__key {
    flex: 1 1 0;
    margin-right: 1rem;
  }
  &__value {
    flex: 1 1 0;
  }
}

