.brand {
  padding-top: 0 !important;
}

.brandImageContainer {
  height: 32px;
}

.brandImage {
  display: block;
  width: auto;
  height: 100%;
}
