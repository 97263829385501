@import "variables";


.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.userButton {
  @include remove-default-button-style();
  font-weight: bold;
  color: inherit;
}
