@import "variables";
@import "~bootstrap/scss/tables";


.noBorders {
  td, th {
    border: none !important;
  }
}

