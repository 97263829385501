@import "variables";
@import "~bootstrap/scss/buttons";

@each $color, $value in $grays {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $grays  {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
