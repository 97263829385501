@import 'variables';

.input-group > .input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
}

.input {
  position: relative;
}

.input__suffix {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-color: transparent;
  background: transparent !important; // should be never changed
  pointer-events: none;
}

.input-group .input:not(:last-child) input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
