.heading {
  margin-bottom: 1em;
  margin-top: 1em;
  text-align: center;
}

.entry {
  padding-top: 0.5em;

}
