@import "variables";
@import "~bootstrap/scss/modal";


.modalWrapper {
  padding: 1rem 1rem 0 1rem;
}

.close {
  float: right
}
