@import "variables";


.button {
  position: relative;
  height: 100%;
  width: 100%;
  @include remove-default-button-style;
  transition: all .2s ease-in-out;
  &:active {
    transform: scale(0.95);
  }
}

.button::before {
  content: '';
  display: block;
  padding-top: 100%;
}


.tile {
  width: 100%;
}

.numContent {
  transition: background-color 250ms ease-in-out;
  position: absolute;
  border-radius: 0.5rem;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--bs-body-bg);
  box-shadow: var(--bs-tertiary-bg) 0 0.2rem 0;
}

.numContent_selected {
  background-color: var(--bs-primary);

}

.numContent_won {
  background-color: var(--bs-success);

}

.numContent_lost {
  background-color: var(--bs-danger);

}

.num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--bs-secondary);
  font-size: 1.25rem;
}
