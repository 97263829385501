@import "variables";

$padding-wrapper1: 0.75rem;
$padding-wrapper2: 0.25rem;


.sliderWrapper1 {
  padding: $padding-wrapper1;
  width: 100%;
  position: relative;
  left: 0;
  background-color: var(--bs-secondary-bg);
}

.sliderWrapper2 {
  padding: $padding-wrapper2;
  border-radius: 0.25rem;
  background-color: var(--bs-body-bg);
}

.wrapper {
  position: absolute;
  width: 100%;
  top: 50%;
  padding-left: $padding-wrapper1 + $padding-wrapper2;
  padding-right: $padding-wrapper1 + $padding-wrapper2;
  pointer-events: none;
  touch-action: none;
}
