@import 'variables';

.diceBetInfo {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  &__wrap {
    position: relative;
    width: 100%;
  }

  &__betNum {
    position: absolute;
    bottom: 100%;
    transform: translate(-50%);
  }

  &__resultNum {
    position: absolute;
    top: 100%;
    transform: translate(-50%);
  }

  &__resultEntry {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__resultHeader {
    @include label-header;
  }
}
