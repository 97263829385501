.form {
  width: 100%;
  margin: 1rem;
}

.ui {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
  padding-top: 5%;
  padding-bottom: 5%;
}
