.verificationInfo {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &__entry {
    padding-bottom: 1em;
  }

  &__value {
    height: auto !important;
    font-family: monospace;
    font-size: 0.75rem;
    overflow: auto;
    word-break: break-all;
  }
}
