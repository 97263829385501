@import 'variables';

.notification {
  font-family: inherit !important;
}

:global {
  .Toastify__toast--error {
    color: $white;
    @include background-color("danger");
  }

  .Toastify__toast--success {
    color: $white;
    @include background-color("success");
  }

  .Toastify__toast--info {
    color: $white;
    @include background-color("info");
  }
}