.heading {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}

.selection {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem !important;
}